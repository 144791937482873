import React, { useState, useEffect } from 'react';
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Spinner } from 'react-bootstrap';


Chart.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const [key, setKey] = useState(0);
  const [loadingChart, setLoadingChart] = useState(true);

  const [labels, setLabels] = useState([]);
  const [counts, setCounts] = useState([]);


  const getColores = () => {
    const darkPastelColors = [
      'rgb(255, 173, 173)',
      'rgb(255, 204, 173)',
      'rgb(255, 230, 173)',
      'rgb(204, 255, 173)',
      'rgb(173, 255, 255)',
      'rgb(173, 204, 255)',
      'rgb(204, 173, 255)',
      'rgb(255, 173, 255)',
      'rgb(255, 115, 115)',
      'rgb(255, 173, 115)',
      'rgb(255, 255, 115)',
      'rgb(115, 255, 115)',
      'rgb(115, 255, 255)',
      'rgb(115, 115, 255)',
      'rgb(173, 115, 255)',
      'rgb(255, 115, 255)',
      'rgb(255, 77, 77)',
      'rgb(255, 140, 77)',
      'rgb(255, 255, 77)',
      'rgb(77, 255, 77)',
      'rgb(77, 255, 255)',
  ];
  
    const backgroundColors = darkPastelColors.map(color => `${color.replace('rgb', 'rgba').replace(')', ', 0.2)')}`);
    const borderColors = darkPastelColors.map(color => `${color.replace('rgb', 'rgba').replace(')', ', 1)')}`);


    return { backgroundColors, borderColors };
  };

  const { backgroundColors, borderColors } = getColores();

  useEffect(() => {
    // Actualiza la key cuando los datos cambien para forzar el remontaje del componente
    setKey((prevKey) => prevKey + 1);

    const MAX_VALUES = 20;
    const topEntries = data.slice(0, MAX_VALUES);
    const otherEntries = data.slice(MAX_VALUES);

    let otherCount = 0;
    otherEntries.forEach(([, count]) => {
      otherCount += count;
    });

    const topLabels = topEntries.map(([value]) => value);
    const topCounts = topEntries.map(([, count]) => count);

    if (otherCount > 0) {
      topLabels.push('Otros');
      topCounts.push(otherCount);
    }

    setLabels(topLabels);
    setCounts(topCounts);

    if (data.length > 0) {
      setLoadingChart(false); // Cuando los datos están listos, establece el estado de carga como false
    }


  }, [data]);


  const chartData = {
    labels: labels,
    datasets: [
      {
        label: '',

        data: counts,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  if (loadingChart) return (                <Spinner animation="border" role="status" />
  )

  return (
    <Pie
      key={key} // Asigna una key única para forzar el remontaje del componente
      data={chartData}
    />
  );
};

export default PieChart;
