import React, { useState, useMemo, useEffect } from 'react';
import { useTable, usePagination, useFilters } from 'react-table';
import { Spinner, Table, Form, Modal, Button, Row, Col } from 'react-bootstrap';
import PieChart from '../componentes/PieChart';
import ColumnFilter from '../componentes/ColumnFilter';
import { calculateSum, countUniqueValues, formatNumber, handleFileUpload } from '../utils/utils';
import useFileUploader from '../utils/useFileUploader';
import * as XLSX from 'xlsx';
import { handlePrint } from '../utils/printUtils';
import DataTable from '../componentes/DataTable';
import DetalleTable from '../componentes/DetalleTable';

const FileUploader = () => {
    const [loadingGrafico, setLoadingGrafico] = useState(false);
    const [uniqueValues, setUniqueValues] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showDialogGrafico, setshowDialogGrafico] = useState(false);
    const [showDialogSumatoria, setshowDialogSumatoria] = useState(false);
    const [sumatoria, setSumatoria] = useState(0);
    const { excelData, setExcelData, loading, setLoading /* Otros estados y funciones set */ } = useFileUploader();

    const handleUpload = (e) => {
        handleFileUpload(e.target.files[0], setLoading, setExcelData);
    };

    const formattedSumatoria = formatNumber(sumatoria);


    const columns = useMemo(() => {
        if (excelData?.length > 0) {
            return excelData[0].map((header, index) => ({
                Header: header,
                accessor: index.toString(),
                Filter: ColumnFilter // Asigna el filtro personalizado para cada columna
            }));
        }
        return [];
    }, [excelData]);

    const tableInstance = useTable(
        {
            columns,
            data: useMemo(() => excelData.slice(1), [excelData]),
            initialState: { pageIndex: 0, pageSize: 10 }, // Página inicial y tamaño
        },
        useFilters,
        usePagination
    );

    const {
        rows,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        state,
        setGlobalFilter,
        prepareRow,
        pageCount, // Accede a pageCount desde state
    } = tableInstance;

    const { pageIndex, pageSize, filters } = state;


    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);

    // Manejador de clic para la fila
    const handleRowClick = (row) => {
        setSelectedRowData(row.original);
        setShowDialog(true);
    };

    const exportToExcel = () => {
        if (page.length > 0) {
            const filteredDataExcel = rows.map((row) => row.original);
            const columnHeaders = headerGroups[0].headers.map((column) => column.Header);


            const ws = XLSX.utils.json_to_sheet([columnHeaders, ...filteredDataExcel], {
                skipHeader: true,
            });

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Datos Filtro');
            XLSX.writeFile(wb, 'datos_filtrados.xlsx');
        }
    };
    const handlePrintFila = () => {
        handlePrint('modalContent');
    }

    const handlePrintGrafico = () => {
        handlePrint('modalContentGrafico');
    }

    const handlePrintSumatoria = () => {
        handlePrint('modalContentSumatoria');
    }



    const obtenerNombreDeColumna = (columnIndex) => {
        try {
            return columns[columnIndex]?.Header;
        }
        catch {
            return '';
        }
    };



    const [shouldCalculateUniqueValues, setShouldCalculateUniqueValues] = useState(false);
    const [shouldCalculateSumatoria, setShouldCalculateSumatoria] = useState(false);




    const handleGenerateChart = (columnName) => {
        setshowDialogGrafico(true);
        setUniqueValues([]);
        setSelectedColumn(columnName);
        setLoadingGrafico(true); // Establece loadingGrafico como true al iniciar el procesamiento
        setShouldCalculateUniqueValues(true);
    };
    const handleGenerateSumatoria = (columnName) => {
        setshowDialogSumatoria(true);
        setSumatoria(0);
        setSelectedColumn(columnName);
        setLoadingGrafico(true); // Establece loadingGrafico como true al iniciar el procesamiento
        setShouldCalculateSumatoria(true);
    };


    // Llama a countUniqueValues solo si shouldCalculateUniqueValues es true
    useEffect(() => {
        const fetchData = async () => {
            if (shouldCalculateUniqueValues) {
                try {
                    const result = await countUniqueValues(rows.map((row) => row.original[selectedColumn]));
                    setUniqueValues(result);
                    setShouldCalculateUniqueValues(false);
                    setLoadingGrafico(false);
                } catch (error) {
                    console.error('Error al obtener valores únicos:', error);
                    setLoadingGrafico(false);
                }
            }
        };

        fetchData();
    }, [shouldCalculateUniqueValues, selectedColumn]);


    useEffect(() => {
        const fetchData = async () => {
            if (shouldCalculateSumatoria) {
                try {
                    const result = await calculateSum(rows.map((row) => row.original[selectedColumn]));

                    setSumatoria(result);
                    setShouldCalculateSumatoria(false);
                    setLoadingGrafico(false);
                } catch (error) {
                    console.error('Error al obtener valores únicos:', error);
                    setLoadingGrafico(false);
                }
            }
        };

        fetchData();
        console.log('sumatoria: ', sumatoria)
    }, [shouldCalculateSumatoria, selectedColumn]);


    return (
        <div>
            <input type="file" className='form-control'
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleUpload} />
            {loading ? (
                <Spinner animation="border" role="status" />
            ) : excelData?.length > 0 ? (
                <div>
                    <DataTable
                        headerGroups={headerGroups}
                        getTableProps={getTableProps}
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                        handleRowClick={handleRowClick}
                        handleGenerateChart={handleGenerateChart}
                        handleGenerateSumatoria={handleGenerateSumatoria}
                    />                    {/* Diálogo */}
                    <Modal show={showDialog} onHide={() => setShowDialog(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detalles de la Fila</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div id="modalContent">
                                {selectedRowData && (
                                    <DetalleTable headerGroups={headerGroups} selectedRowData={selectedRowData} />
                                )}
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDialog(false)}>
                                Cerrar
                            </Button>
                            <Button variant="primary" onClick={handlePrintFila}>
                                Imprimir
                            </Button>
                        </Modal.Footer>

                    </Modal>
                    {/* Diálogo grafico */}
                    <Modal size="lg"
                        show={showDialogGrafico} onHide={() => setshowDialogGrafico(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Gráfico de {obtenerNombreDeColumna(selectedColumn)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div id="modalContentGrafico">
                                {loadingGrafico ? (
                                    <Spinner animation="border" role="status" />
                                ) : (
                                    <Row>
                                        <Col className='tablaGrafico'>
                                            <Table striped bordered>
                                                <thead>
                                                    <tr>
                                                        <th>{obtenerNombreDeColumna(selectedColumn)}</th>
                                                        <th>Cantidad</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {uniqueValues.map(([value, count]) => (
                                                        <tr key={value}>
                                                            <td>{value}</td>
                                                            <td>{count}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col style={{ marginTop: '20px' }}>
                                            <PieChart data={uniqueValues} />
                                        </Col>
                                    </Row>)}
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setshowDialogGrafico(false)}>
                                Cerrar
                            </Button>
                            <Button variant="primary" onClick={handlePrintGrafico}>
                                Imprimir
                            </Button>
                        </Modal.Footer>

                    </Modal>
                    <Modal show={showDialogSumatoria} onHide={() => setshowDialogSumatoria(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sumatoria de {obtenerNombreDeColumna(selectedColumn)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div id='modalContentSumatoria'>
                                <h1>Total {obtenerNombreDeColumna(selectedColumn)}</h1>
                                <h2>{formattedSumatoria}</h2>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setshowDialogSumatoria(false)}>
                                Cerrar
                            </Button>
                            <Button variant="primary" onClick={handlePrintSumatoria}>
                                Imprimir
                            </Button>
                        </Modal.Footer>

                    </Modal>
                    <div>
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Anterior
                        </Button>
                        <span>
                            Página{' '}
                            <strong>
                                {pageIndex + 1} de {pageCount}
                            </strong>{' '}
                        </span>
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            Siguiente
                        </Button>
                    </div>

                    <div>
                        <Button onClick={exportToExcel} className='mt-3' disabled={page.length === 0}>
                            Exportar a Excel con Filtros
                        </Button>
                    </div>
                    {loadingGrafico ? (
                        <Spinner animation="border" role="status" />
                    ) : null}
                </div>


            ) : null}
        </div>
    );
};

export default FileUploader;
