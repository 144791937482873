import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav, Row } from 'react-bootstrap';
import FileUploader from './screens/FileUploader';
import "./App.css"
function App() {
  return (
    <div className="App">
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">EXCEL TO TABLE</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Inicio</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Row className="mt-4">
        <h1>Subir archivo excel</h1>
        <FileUploader />
      </Row>
      <Navbar bg="dark" variant="dark" className="mt-5">
        <Container>
          <Navbar.Brand href="#home">2024 FZ</Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}

export default App;
