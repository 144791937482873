import React from 'react';
import { Table, Button, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faCalculator } from '@fortawesome/free-solid-svg-icons';

const DataTable = ({
    headerGroups,
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    handleRowClick,
    handleGenerateChart, // Importa esta función si se define en otro lugar
    handleGenerateSumatoria, // Importa esta función si se define en otro lugar
}) => {
    return (
        <div>
            <Table striped bordered hover {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        {headerGroups.map((headerGroup) =>
                            headerGroup.headers.map((column) => (
                                <td key={column.id}>
                                    <Button
                                        variant="primary" className='m-1'
                                        onClick={() => handleGenerateChart(column.id)}
                                    >
                                        <FontAwesomeIcon icon={faChartPie} />

                                    </Button>
                                    <Button
                                        variant="primary" className='m-1'
                                        onClick={() => handleGenerateSumatoria(column.id)}
                                    >
                                        <FontAwesomeIcon icon={faCalculator} />

                                    </Button>
                                </td>
                            ))
                        )}
                    </tr>
                </tfoot>
            </Table>        </div>
    );
};

export default DataTable;
