export const handlePrint = (elementId) => {
    const elementToPrint = document.getElementById(elementId);

    if (elementToPrint) {
        const printContents = elementToPrint.innerHTML;
        const chartCanvas = elementToPrint.querySelector('canvas');
        const chartImageData = chartCanvas?.toDataURL();

        const printWindow = window.open('', '_blank');
        printWindow.document.open();

        printWindow.document.write(`
            <html>
                <head>
                    <title>ARCA - Impresión</title>  
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                </head>
                <body>
                    ${printContents}
                    ${chartImageData ? `<img src="${chartImageData}" />` : ''}
                </body>
            </html>
        `);

        printWindow.document.close();

        printWindow.onload = () => {
            printWindow.focus();
            printWindow.print();
            printWindow.onafterprint = () => {
                printWindow.close();
            };
        };
    } else {
        console.error(`Element '${elementId}' does not exist.`);
    }
};