// useFileUploader.js

import { useState } from 'react';

const useFileUploader = () => {
    const [excelData, setExcelData] = useState([]);
    const [loading, setLoading] = useState(false);
    // Otros estados y funciones set que desees extraer del componente FileUploader

    return {
        excelData,
        setExcelData,
        loading,
        setLoading,
        // Otros estados y funciones set que quieras exportar
    };
};

export default useFileUploader;

