import React from 'react';
import { Form } from 'react-bootstrap';

const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
        <Form.Control
            type="text"
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={`Filtrar ${column.Header}`}
        />
    );
};

export default ColumnFilter;
