// QuantityTable.js

import React from 'react';
import { Table } from 'react-bootstrap';

const DetalleTable = ({ headerGroups, selectedRowData }) => {
    return (
        <Table striped bordered>
            <thead></thead>
            <tbody>
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) => (
                        <tr key={column.id}>
                            <th>{column.render('Header')}</th>
                            <td>{selectedRowData[column.id]}</td>
                        </tr>
                    ))
                )}
            </tbody>
        </Table>
    );
};

export default DetalleTable;
