import * as XLSX from 'xlsx';

export const countUniqueValues = (columnData) => {
    const uniqueValuesCount = columnData.reduce((acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});
  
    const entries = Object.entries(uniqueValuesCount).sort(([, countA], [, countB]) => countB - countA);
    return entries;
  };
  
  export const calculateSum = (columnData) => {
    const numericValues = columnData.map((value) => parseFloat(value)).filter((value) => !isNaN(value));
    const sum = numericValues.reduce((acc, value) => acc + value, 0);
    return sum;
  };
  

export const handleFileUpload = (file, setLoading, setExcelData) => {
    try {
        setLoading(true);
        const reader = new FileReader();

        reader.onload = (event) => {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            setExcelData(parsedData);
            setLoading(false);
        };

        reader.readAsBinaryString(file);
    }
    catch {
        console.log('Error cargando archivo');
        setLoading(false);
    }
};



export const formatNumber = (number) => {
    return number.toLocaleString('es-AR', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
};
